.floor-plan-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;

  .modal-header {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 16px;
  }

  .modal-body {
    margin-bottom: 16px;
    text-align: center;

    .text-input {
      width: 100%;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-evenly;
  }
}
