.floor-plan-list {
  display: flex;
  justify-content: center;

  .table-container {
    display: flex;
    flex-direction: column;

    .new-floor-plan-button {
      align-self: flex-end;
    }
  }
}
