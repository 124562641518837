.file-upload {
  padding: 2rem;
  border: 1px dashed grey;
  margin: 2rem auto;
  border-radius: 0.25rem;
  width: 100%;
  box-sizing: border-box;

  &.error {
    border-color: red;
  }

  .file-upload-description {
    margin-bottom: 1.5rem;
    word-break: break-all;
  }
}
